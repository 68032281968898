/** @format */

import { makeAutoObservable } from "mobx";

export default class DemoState {
    data: string;

    constructor() {
        this.data = "eating";
        makeAutoObservable(this);
    }

    changeData() {
        this.data = "sleeping !!";
    }
}
