/** @format */
/* eslint-disable import/prefer-default-export */

import SuperAdminService from "../services/SuperAdminService";

const fun1 = async () => {
    const data = await SuperAdminService.getSupConfig();
    return data.orgLogo;
};

export const BGURL = fun1();

export const BATCH = "Batch";
// socket events and emits
export const SINGLE_FOOTER_COORDINATES = "singleFooterCoordinates";
export const MULTI_FOOTER_COORDINATES = "multiFooterCoordinates";

export const CONFIRM_FOOTER_TEXT = "CONFIRM_FOOTER_TEXT";

export const SINGLE_PAGE_FOOTER_TEXT = "singlePageFooterText";
export const MULTI_PAGE_FOOTER_TEXT = "multiPageFooterText";

export const PROCESSED_DATA_RESPONSE = "processedDataResponse";
export const AUTO_COORDINATES_RESPONSE = "autoCoordinatesResponse";

export const ON_CREATE_TEMPLATE_IDENTIFIER = "onCreateTemplateIdentifier";
export const ON_BATCH_COMPLETE = "onBatchComplte";

// process types
export const AUTOMATIC = "automatic";
export const SEMI_AUTOMATIC = "semiAutomatic";
export const MANUAL = "manual";

// invoice Type
export const COMP_GENERATED = "compGenerated";
export const HANDWRITTEN = "handWritten";

export const MANAGE_TEMPLATE = "manageTemplate";

export const TEMP_PROCESS = "tempProcess";
export const SAVE_PROCESS = "save Process";
export const YOLO_COORDINATES = "yoloCoOrdinates";

export const CLASS_LIST = [
    { className: "Name", value: "name" },
    { className: "Address", value: "address" },
    { className: "Phone Number", value: "phone_number" },
    { className: "Email Id", value: "email_id" },
    { className: "Gender", value: "gender" },
    { className: "Date Of Birth", value: "date_of_birth" },
    { className: "Nationality", value: "nationality" },
    { className: "Personal Information", value: "personal_information" },
    { className: "Work Experience", value: "work_experience_1" },
    { className: "Education", value: "education_1" },
    { className: "Language", value: "language" },
    { className: "Table 1", value: "table_1" },
    { className: "Other Skills", value: "other_skills_1" },
    { className: "Table 2", value: "table_2" },
    { className: "Driving License", value: "driving_license" },
    { className: "Fiscal Code", value: "fiscal_code" },
];

export const CLASS_LIST2 = [
    { className: "Name", value: "name" },
    { className: "Address", value: "address" },
    { className: "Phone Number", value: "phone_number" },
    { className: "Email Id", value: "email_id" },
    { className: "Gender", value: "gender" },
    { className: "Date Of Birth", value: "date_of_birth" },
    { className: "Nationality", value: "nationality" },
    { className: "Personal Information", value: "personal_information" },
    { className: "Work Experience", value: "work_experience" },
    { className: "Education", value: "education" },
    { className: "Language", value: "language" },
    { className: "Table 1", value: "table_1" },
    { className: "Other Skills", value: "other_skills" },
    { className: "Table 2", value: "table_2" },
    { className: "Driving License", value: "driving_license" },
    { className: "Fiscal Code", value: "fiscal_code" },
];

export const ADD_TEMPLATE = "create";
// export const EDIT_TEMPLATE = "edit";

// * list of constants
// FIRST SCREEN OPTIONS
export const SINGLE = "single";
export const MULTIPLE = "multiple";

// SCREEN TYPES
export const SELECT_SCAN_SCREEN = "selectScanTypeScreen";
export const SELECT_TAMPLATE_SCREEN = "selectTemplateScreen";
export const UPLOAD_DOCUMENT_SCREEN = "uploadDocumentScreen";
export const UPLOAD_SINGLE_CV_SCREEN = "uploadCVScreen";
export const UPLOAD_MULTIPLE_CV_SCREEN = "uploadMultipleCVScreen";
export const SELECT_PROCESS_TYPE_SCREEN = "selectProcessTypeScreen";
export const DATA_EXTRATION_SCREEN = "data_extraction_screen";

export const CURRENT_PROCESS_PERCENTAGE = "currentProcesPercentage";

export const DEVELOPMENT = "development";
export const PRODUCTION = "production";

export const START = "START";
export const STOP = "STOP";

export const BATCH_PROGRESS_COUNT = "batchProgressCount";
export const BATCH_PROGRESS_COUNT_UPDATE = "batchProgressCountUpdate";
export const BATCH_PROGRESS_COMPLETE_UPDATE = "batchProgressCompleteUpdate";

export const CURRENT_BATCH_STATUS = "CURRENT_BATCH_STATUS";

export const OPERATION_CV_STATUS = "OPERATION_CV_STATUS";
export const OPERATION_CANDIDATE = "OPERATION_CANDIDATE";

// V2 CONSTANTS

export const INVOICE_COORDINATES = "invoiceCoordinates";
export const PROCESSED_DATA = "processedData";
export const ON_AUTO_SELECT_TEMPLATE = "onAutoSelectTemplate";

// ACTIONS
export const CREATE_TEMPLATE = "createTemplate";
export const EDIT_TEMPLATE = "editTemplate";
export const CREATE_INVOICE = "createInvoice";
export const EDIT_INVOICE = "editInvoice";
export const MODEL_TRAIN = "modelTrain";

// INVOICE TYPE VAIRABLE|FIXED
export const FIXED = "fixed";
export const VARIABLE = "variable";

//
export const CLASS_DATA_TYPES = {
    LINE_ITEM: "Line Item",
    HEADER: "Header",
    OTHER: "Other",
    TEXT: "Text",
    NUMBER: "Number",
    DATE: "Date",
    NA: "N/A",
};

export const LINE_ITEM_ORDER = ["serial_number", "item_number", "descriptions", "quantity", "price", "amount", "unit", "tax"];

export const DATA_ORIGIN = {
    UI: "UI",
    API: "API",
    BATCH: "BATCH",
};

export const CREATE_CLASS = "createClass";

export const EDIT_CLASS = "editClass";

export const ON_BATCH_ERROR = "batchError";

export default {};
