/** @format */

import { EventEmitter } from "events";

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/sort-comp */

/* eslint-disable no-bitwise */

const createUUID = () => {
    const pattern = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
    return pattern.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

const Constants = {
    CHANGE: "change",
    PRIMARY: "primary",
    SECONDARY: "secondary",
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
};

class NotificationManager extends EventEmitter {
    listNotify: any[];

    constructor() {
        super();
        this.listNotify = [];
    }

    create(notify: { type?: string; message?: any; title?: any; timeOut?: any; onClick?: any; priority: any; customClassName?: any }) {
        const defaultNotify = {
            id: createUUID(),
            type: "info",
            title: null,
            message: null,
            timeOut: 5000,
            customClassName: "",
        };
        if (notify.priority) {
            this.listNotify.unshift(Object.assign(defaultNotify, notify));
        } else {
            this.listNotify.push(Object.assign(defaultNotify, notify));
        }
        this.emitChange();
    }

    primary(message: any, title: any, timeOut: any, onClick: any, priority: any, customClassName: any) {
        this.create({
            type: Constants.PRIMARY,
            message,
            title,
            timeOut,
            onClick,
            priority,
            customClassName,
        });
    }

    secondary(message: any, title: any, timeOut: any, onClick: any, priority: any, customClassName: any) {
        this.create({
            type: Constants.SECONDARY,
            message,
            title,
            timeOut,
            onClick,
            priority,
            customClassName,
        });
    }

    info(message: any, title: any, timeOut: any, onClick: any, priority: any, customClassName: any) {
        this.create({
            type: Constants.INFO,
            message,
            title,
            timeOut,
            onClick,
            priority,
            customClassName,
        });
    }

    success(message?: string, title?: string, timeOut?: number, onClick?: null, priority?: null, customClassName?: string) {
        this.create({
            type: Constants.SUCCESS,
            message,
            title,
            timeOut,
            onClick,
            priority,
            customClassName,
        });
    }

    warning(message: string, title: string, timeOut: number, onClick: any, priority: any, customClassName: string) {
        this.create({
            type: Constants.WARNING,
            message,
            title,
            timeOut,
            onClick,
            priority,
            customClassName,
        });
    }

    error(
        message?: any,
        title?: string,
        timeOut?: number,
        onClick?: { (): void; (): void; (): void; (): void; (): void; (): void; (): void },
        priority?: any,
        customClassName?: string
    ) {
        this.create({
            type: Constants.ERROR,
            message,
            title,
            timeOut,
            onClick,
            priority,
            customClassName,
        });
    }

    remove(notification: { id: any }) {
        this.listNotify = this.listNotify.filter((n: { id: any }) => notification.id !== n.id);
        this.emitChange();
    }

    emitChange() {
        this.emit(Constants.CHANGE, this.listNotify);
    }

    addChangeListener(callback: { (notifications: any): void; (...args: any[]): void }) {
        this.addListener(Constants.CHANGE, callback);
    }

    removeChangeListener(callback: { (notifications: any): void; (...args: any[]): void }) {
        this.removeListener(Constants.CHANGE, callback);
    }
}

export default new NotificationManager();
