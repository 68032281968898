/** @format */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */

import { makeAutoObservable } from "mobx";
import LogService from "../../services/LogService";
import UserService from "../../services/UserService";

export default class RefactoringState {
    classesList: Array<any> = [];

    isClassListLoading = false;

    setIsClassListLoading(value) {
        this.isClassListLoading = value;
    }

    async loadClassesList() {
        this.setIsClassListLoading(true);
        const fetchClassesList = await UserService.fetchClassessList();
        const { classNames } = fetchClassesList.invoiceClassesList;
        this.classesList = classNames;
        this.setIsClassListLoading(false);
    }

    // THESE IS REFACTORING FOR UI DISPLAY WHILE SENDING FOR DATA EXTRACTION SHOULD BE REVERTED
    // eslint-disable-next-line consistent-return
    // eslint-disable-next-line class-methods-use-this
    async refactorClassesForUI(coordinates: any): Promise<any> {
        const { classesList } = this;

        // console.log("classesList", classesList);
        // console.log("coordinates", coordinates);
        // return;

        const deepCopiedYoloCoordinates = JSON.parse(JSON.stringify(coordinates));
        // console.log(deepCopiedYoloCoordinates);
        // FOR SINGLE PAGE ARRAY
        if (Array.isArray(deepCopiedYoloCoordinates)) {
            deepCopiedYoloCoordinates.map((yoloObj) => {
                const currentClassData = classesList.find((classObj) => classObj.className === yoloObj.comment);
                yoloObj.comment = currentClassData?.displayName;
                yoloObj.className = currentClassData?.className;
                // yoloObj.dataType = currentClassData.dataType;
                // yoloObj.labelType = currentClassData.labelType;
                return yoloObj;
            });
            return deepCopiedYoloCoordinates;
        }

        // ON MULTI PAGE OBJECT
        if (!Array.isArray(deepCopiedYoloCoordinates)) {
            Object.keys(deepCopiedYoloCoordinates).forEach((pageName) => {
                deepCopiedYoloCoordinates[pageName].map((yoloObj) => {
                    // console.log(yoloObj);
                    // return;
                    const currentClassData = classesList.find((classObj) => classObj.className === yoloObj.comment);
                    if (currentClassData) {
                        yoloObj.comment = currentClassData?.displayName;
                        yoloObj.className = currentClassData?.className;
                        // yoloObj.dataType = currentClassData.dataType;
                        // yoloObj.labelType = currentClassData.labelType;
                        return yoloObj;
                    }
                    return yoloObj;
                });
            });
            return deepCopiedYoloCoordinates;
        }
        return "";
    }

    // eslint-disable-next-line consistent-return
    addYoloClassTypesInSinglePageInvoice(singlePageInvoiceClasses: any): any {
        const classNames = this.classesList;
        try {
            // eslint-disable-next-line array-callback-return
            singlePageInvoiceClasses.map((classObj) => {
                const comment = classObj?.["comment"];
                if (comment) {
                    const classDataType = classNames.find((obj) => obj["className"] === comment);
                    classObj["dataType"] = classDataType?.["dataType"];
                    classObj["labelType"] = classDataType?.["labelType"];
                }
            });
            return singlePageInvoiceClasses;
        } catch (error) {
            LogService.error(`ExtractProcessEventService --> addYoloClassTypesInSinglePageInvoice --> error: ${error.message}`);
        }
    }

    // eslint-disable-next-line consistent-return
    addYoloClassTypesInMultiPageInvoice(multiPageInvoiceClasses: any): any {
        const classNames = this.classesList;
        try {
            // eslint-disable-next-line array-callback-return
            Object.keys(multiPageInvoiceClasses).map((pageName) => {
                if (Array.isArray(multiPageInvoiceClasses[pageName])) {
                    // eslint-disable-next-line array-callback-return
                    multiPageInvoiceClasses[pageName].map((classObj) => {
                        if (classObj["comment"]) {
                            const comment = classObj["comment"];
                            const classDataType = classNames.find((obj) => obj["className"] === comment);
                            classObj["dataType"] = classDataType?.["dataType"];
                            classObj["labelType"] = classDataType?.["labelType"];
                        }
                    });
                }
            });
            return multiPageInvoiceClasses;
        } catch (error) {
            LogService.error(`ExtractProcessEventService --> addYoloClassTypesInMultiPageInvoice --> error: ${error.message}`);
        }
    }

    // FILTER ALL THE CO-ORDINATES WHICH DON'T HAVE COMMENT

    // eslint-disable-next-line class-methods-use-this
    refactorClassesForDataExtraction(annotateClasses: any[] | any): any {
        // changing classname with comment keyword
        //     for single page data is array
        if (Array.isArray(annotateClasses)) {
            let deepCopiedAnnotateClasses: any[] = JSON.parse(JSON.stringify(annotateClasses));
            // console.log("deepCopiedAnnotateClasses", deepCopiedAnnotateClasses);
            // return;
            deepCopiedAnnotateClasses = deepCopiedAnnotateClasses
                .filter((obj) => obj.comment) // map and filtering on comment. if comment prsent then processed to save on db
                .map((obj) => {
                    const yoloObj = this.classesList.find((item) => item.displayName === obj.comment);
                    const { className } = yoloObj;
                    // if class name is present then swapt class name with comment
                    // with workflow like get footer coordinate or auto didnt recive class name so  dont do anything
                    if (className) {
                        obj.comment = className;
                    }
                    return obj;
                });

            deepCopiedAnnotateClasses = this.addYoloClassTypesInSinglePageInvoice(deepCopiedAnnotateClasses);

            return deepCopiedAnnotateClasses;
        }

        // for multi page it is object
        if (Array.isArray(annotateClasses) === false) {
            const deepCopiedClassObject: any[] = JSON.parse(JSON.stringify(annotateClasses));
            let updatedAnnotateObject = {};
            // iterating over object keys
            Object.keys(deepCopiedClassObject).forEach((pageName) => {
                updatedAnnotateObject[pageName] = [];
                // changing comment with classname
                const pageAnnoatationsClasses: [] = deepCopiedClassObject[pageName]
                    .filter((obj) => obj.comment) // map and filtering on comment. if comment prsent then processed to save on db;
                    .map((obj) => {
                        const yoloObj = this.classesList.find((item) => item.displayName === obj.comment);
                        const { className } = yoloObj;
                        if (className) {
                            obj.comment = className;
                        }
                        return obj;
                    });

                // combining with new updated object
                updatedAnnotateObject[pageName] = [...pageAnnoatationsClasses];
            });

            updatedAnnotateObject = this.addYoloClassTypesInMultiPageInvoice(updatedAnnotateObject);
            return updatedAnnotateObject;
        }

        return [];
    }
}
