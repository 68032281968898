/** @format */

import axios from "axios";

const isProduction = process.env.NODE_ENV === "production";

// export const baseURL = isProduction ? `https://${process.env.REACT_APP_API_DOMAIN}/api` : "http://localhost:5000/api";
// export const socketBaseURL = isProduction ? `https://${process.env.REACT_APP_API_DOMAIN}` : "http://localhost:5000/";

export const baseURL = isProduction ? `http://${process.env.REACT_APP_API_DOMAIN}/api` : "http://localhost:5000/api";
export const socketBaseURL = isProduction ? `http://${process.env.REACT_APP_API_DOMAIN}` : "http://localhost:5000/";

const Axios = axios.create({
    baseURL,
});

// get token from localstorage
const tokenFromLocalStorage = localStorage.getItem("jwtToken");

// setting up token from localstorage if exists
Axios.defaults.headers.common.Authorization = tokenFromLocalStorage;
export default Axios;
