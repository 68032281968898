/** @format */

// NOTE: define your custome theme variables here
/**
 * for any height , width, margin , color
 * define variables as primary, secondary, ternary or large, medium, small
 * below variables defined for demo only please define as per your preference
 */
const StyledTheme = {
    HEIGHT_LARGE: "10rem",
    HEIGHT_MEDIUM: "7rem",
    HEIGHT_SMALL: "4rem",

    COLOR_PRIMARY: "blue",

    TEXT_ALIGN_LEFT: "left",
    TEXT_ALIGN_CENTER: "center",
    TEXT_ALIGN_RIGHT: "right",

    LIST_STYLE_NONE: "none",

    RELATIVE_SIZE_EXTRA_SMALL: "15%",
    RELATIVE_SIZE_SMALL: "25%",
    RELATIVE_SIZE_MEDIUM: "50%",
    RELATIVE_SIZE_LARGE: "75%",
    RELATIVE_SIZE_LARGER: "100%",

    ABSOLUTE_SIZE_EXTRA_SMALL: "15px",
    ABSOLUTE_SIZE_SMALL: "25px",
    ABSOLUTE_SIZE_MEDIUM: "30px",
    ABSOLUTE_SIZE_LARGE: "40px",
    ABSOLUTE_SIZE_LARGER: "50px",

    MARGIN_EXTRA_SMALL: "0.7rem",
    PADDING_EXTRA_SMALL: "0.7rem",

    // values from template theme check gogo.dark.custom.scss file
    THEME_COLOR_1: "#145388",
    THEME_COLOR_2: "#2a93d5",
    // FOREGROUND_COLOR: "#30394e",
    FOREGROUND_COLOR: "white",
};
export default StyledTheme;
