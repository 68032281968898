/**
 * /* eslint-disable no-restricted-syntax
 *
 * @format
 */

/** @format */
/* eslint-disable no-underscore-dangle */
// localStorage.setItem('user_name', 'Rohit'); //store a key/value
// var retrievedUsername = localStorage.getItem('user_name'); //retrieve the key

import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment";
// import socketIoClient from "socket.io-client";
import { NotificationManager } from "../../components/common/react-notifications";
import {
    AUTOMATIC,
    AUTO_COORDINATES_RESPONSE,
    BATCH_PROGRESS_COUNT,
    CLASS_LIST,
    CURRENT_BATCH_STATUS,
    CURRENT_PROCESS_PERCENTAGE,
    MANUAL,
    ON_BATCH_COMPLETE,
    PROCESSED_DATA_RESPONSE,
    START,
} from "../../constants";
import { REFACTORDATA } from "../../constants/refactorData";
import LogService from "../../services/LogService";
import ManageDataService from "../../services/ManageDataService";
// eslint-disable-next-line import/no-cycle
import UserService from "../../services/UserService";
// import { baseURL, socketBaseURL } from "../../utils/API";
import { baseURL } from "../../utils/API";

// TODO:
// 1. reduce unwanted states from this file as most of the states are shifted on other states

export default class UIState {
    currentSelectedFile = undefined;

    annotatePages = {};

    currentSelectedPage = "";

    currentSelectedPageNumber = 0;

    socketId = undefined;

    imgPreviewPath = undefined;
    // imgPreviewPath = `${baseURL}/uploads/manual/j2ab15hbke6`;

    currentFolderPath: any = undefined;

    currentFilePath: any;

    totalPages: any;

    socket: any;

    processedData = undefined;

    isUploading = false;

    isProcessing = false;

    processType = undefined;

    labelKeyMap = {};

    metaDataId = undefined;

    isProcessExecuted = false;

    templateConfigurations = {};

    selectedTemplateId = undefined;

    openConfirmFooterModal = false;

    currentTemplateType = "";

    batchNotifications = [];

    isDataSaved = false;

    // CHECK IN LOCALSTORAGE IF IT IS MORE THAN ZERO THEN SET IT TO MOBX STATE
    batchProgressCountLocalStorage = Number(localStorage.getItem(BATCH_PROGRESS_COUNT));

    batchProgressCount = this.batchProgressCountLocalStorage > 0 ? this.batchProgressCountLocalStorage : 0;

    isProcessedDataEmmited = false;

    cvProcessingProgress = 0;

    currentUserId = undefined;

    currentUserType = undefined;

    metaData = undefined;

    cvMetaData: [] = [];

    cvCandidateData: [] = [];

    cvCandidateSearchedData: [] = [];

    currentProcessedDataId = "";

    batchLogs = [];

    // v2 states
    outputViewPath = "";

    constructor() {
        // mapping key value to replace code names of key
        CLASS_LIST.forEach((obj) => {
            this.labelKeyMap[obj.value] = obj.className;
        });
        makeAutoObservable(this);
    }

    // computed properties
    // checking yolo coordinates arrived
    get isAutoProcessDone() {
        if (this.processType === AUTOMATIC) {
            return this.annotatePages[this.currentSelectedPage] && this.annotatePages[this.currentSelectedPage].length !== 0;
        }
        if (this.processType === MANUAL) {
            return true;
        }
        return true;
    }

    // TO INITIALIZE SOCKET AND SAVE SOCKET ID
    // socketInit() {
    //     // this.currentUserType = "user";
    //     // this.fetchMetaData(this.currentUserType);
    //     const socket = socketIoClient(socketBaseURL);
    //     this.socket = socket;
    //     socket.on("connect", () => {
    //         this.socketId = socket.id;

    //         this.socket.emit("createProgressRoom", { userId: this.currentUserId });
    //         this.onSocketListen();

    //         this.fetchMetaData(this.currentUserType);
    //         // console.log("this.currentUserType", this.currentUserType);
    //     });
    // }

    // common function to update states
    updateState(stateName, stateData): void {
        this[stateName] = stateData;
    }

    // set batchProgresscount
    setBatchProgressCount = (count) => {
        this.batchProgressCount = count;
    };

    // SOCKET EVENT LISTENERS
    onSocketListen() {
        // on extracted process data response
        this.socket.on(PROCESSED_DATA_RESPONSE, async (response) => {
            LogService.info("processed data response 🔵 ", response);

            if (response) {
                this.isProcessedDataEmmited = true;
                this.cvProcessingProgress = 100;
                this.isProcessing = false;
                setTimeout(() => {
                    this.refactorProcessedData({ ...response });
                }, 1300);
            }

            if (!response) {
                NotificationManager.error("data-extration-error", "Data Extraction Error", 3000);
                this.isProcessing = false;
            }
        });

        // on yolo cooridnates response
        this.socket.on(AUTO_COORDINATES_RESPONSE, async (response) => {
            LogService.info("auto coordinates  response 🔵 ", response);
            if (Array.isArray(response)) {
                this.annotatePages[this.currentSelectedPage] = [...response];
            }

            if (!Array.isArray(response)) {
                this.annotatePages = { ...response };
            }
        });
        // on batch complete resposne

        this.socket.on(ON_BATCH_COMPLETE, async (response) => {
            LogService.info("batch completed", response);
            this.batchNotifications.push({
                title: "Batch process completed",
                date: moment().format("dd-mm-yyyy hh:mm:ss"),
                id: Math.random().toString(16).slice(2),
            });

            this.setBatchProgressCount(0);
            localStorage.setItem("batchStartTime", undefined);
            //
            localStorage.setItem(BATCH_PROGRESS_COUNT, String(0));
            // after completing batch fetch new updated data in chunks
            this.fetchMetaData(this.currentUserType);
        });

        // on listen batch status
        // this.socket.on(`${CURRENT_PROCESS_PERCENTAGE}`, async (response) => {
        //     //
        //     console.log(`current percentage is -> ${response}`);
        //     this.setBatchProgressCount(response);
        //     localStorage.setItem(BATCH_PROGRESS_COUNT, String(response));
        // });

        // on connect listen batch status
        // this.socket.on(CURRENT_BATCH_STATUS, async (response) => {
        //     if (response.batchStatus === false) {
        //         this.setBatchProgressCount(0);
        //         localStorage.setItem(BATCH_PROGRESS_COUNT, String(0));
        //     }
        // });
    }

    increamentCVProcessingProgress(command) {
        const refeshIntervalId = setInterval(() => {
            if (command === START) {
                if (this.cvProcessingProgress <= 94) this.cvProcessingProgress += 2;
                if (this.cvProcessingProgress === 100) clearInterval(refeshIntervalId);
            }
        }, 200);
    }

    // refactoring processed data coming from server as per react table format
    refactorProcessedData(data) {
        const refactoredData = [];
        // eslint-disable-next-line no-param-reassign
        // data = REFACTORDATA(data);
        //
        Object.keys(data).forEach((key) => {
            refactoredData.push({ label: key, data: data[key] });
        });

        // refactordata ==> will add empty input box for unmarked/unextrated classname
        this.processedData = [...refactoredData];

        this.processedData = REFACTORDATA(this.processedData);

        // setting is processing stat to false
        this.setLoadingState("isProcessing", false);
    }

    // update react table processed data
    updateProcessedData({ rowIndex, columnId, value, isChecked }) {
        this.processedData.forEach((row: any, index) => {
            if (index === rowIndex) {
                // updating the index of process data obj as with map not working
                this.processedData[index] = {
                    ...this.processedData[rowIndex],
                    [columnId]: value,
                    isChecked,
                };
            }
            return row;
        });
    }

    setSelectedFile(file) {
        this.currentSelectedFile = file;
    }

    /**
     * setCurrentServerPath
     * saving current file path in state
     */
    public setCurrentImgPath(data) {
        if (data !== "") {
            const { isPdf, path, fileData, processType } = data;

            this.currentSelectedPage = `page-1`;
            this.processType = processType;

            // IF FILE IS NOT A PDF AND SINGLE IMAGE
            if (!isPdf) {
                this.imgPreviewPath = `${baseURL}/${path}`;
                this.totalPages = 1;
                this.currentFilePath = path;
            }

            // IF FILE IS  A PDF AND SINGLE PAGE
            if (isPdf && fileData.pages === 1) {
                this.imgPreviewPath = `${baseURL}/${fileData.filePath}`;
                this.currentFolderPath = undefined;
                this.totalPages = +fileData.pages;
                this.currentFilePath = fileData.filePath;
            }
            // IF FILE IS  A PDF AND MULTI PAGE
            if (isPdf && fileData.pages > 1) {
                this.imgPreviewPath = `${baseURL}/${fileData.folderPath}/page-1.png`;
                this.currentFolderPath = fileData.folderPath;
                this.totalPages = +fileData.pages;
            }
        }
        // setting initial state if move back in the page
        else {
            this.currentSelectedPage = "";
            this.processType = undefined;
            this.imgPreviewPath = undefined;
            this.totalPages = undefined;
            this.currentFilePath = undefined;
            this.currentFolderPath = undefined;
            this.totalPages = undefined;
        }
        // SETTING IS UPLOADING STATE TO FALSE
        this.isUploading = false;
    }

    /**
     * setCurrentPage
     * updating the current page state
     */
    public setCurrentPage(pageNumber) {
        this.currentSelectedPage = `page-${pageNumber}`;
        this.currentSelectedPageNumber = +pageNumber;
        this.imgPreviewPath = `${baseURL}/${this.currentFolderPath}/${this.currentSelectedPage}.png`;
    }

    // eslint-disable-next-line class-methods-use-this
    getCurrentPageAnnotateData() {
        // return this.annotatePages[this.currentSelectedPage];
        return null;
    }

    setAnnotatePageData(data) {
        this.annotatePages[this.currentSelectedPage] = data;
    }

    setLoadingState(type: "isProcessing" | "isUploading", status) {
        if (type === "isUploading") {
            this.isUploading = status;
        }

        if (type === "isProcessing") {
            this.isProcessing = status;
        }
    }

    resetPreviewImage() {
        const imgPath = this.imgPreviewPath;
        this.imgPreviewPath = "";

        setTimeout(() => {
            runInAction(() => {
                this.imgPreviewPath = imgPath;
            });
        }, 100);
    }

    // * Actions to change totalPages , currentFolderPath , currentFilePath,
    // * and processedData , metaDataId
    setTotalPages(pages) {
        this.totalPages = pages;
    }

    setCurrentFolderPath(folderPath) {
        this.currentFolderPath = folderPath;
    }

    setCurrentFilePath(filePath) {
        this.currentFilePath = filePath;
    }

    setProcessedData(data) {
        this.processedData = data;
    }

    setMetaDataId(id) {
        this.metaDataId = id;
    }

    setMetaData(metaData) {
        this.metaData = metaData;
    }

    // reset uploaded and processed states
    resetData() {
        LogService.info("Resetting all on start over");

        this.currentSelectedFile = undefined;

        this.annotatePages = {};

        this.currentSelectedPage = "";

        this.currentSelectedPageNumber = 0;

        this.imgPreviewPath = undefined;

        this.currentFolderPath = undefined;

        this.currentFilePath = undefined;

        this.totalPages = undefined;

        this.openConfirmFooterModal = false;

        this.processedData = undefined;

        this.metaData = undefined;

        this.metaDataId = undefined;

        this.isDataSaved = false;

        this.isProcessedDataEmmited = false;

        this.cvProcessingProgress = 0;

        // this.increamentCVProcessingProgress(STOP);
    }

    // remove image and data
    async clearImageAndStates() {
        LogService.info("removing states and data when umount compo");
        const isFolder = !!this.currentFolderPath;
        const filePath = isFolder ? this.currentFolderPath : this.currentFilePath;
        // sending service call when their is any value in both
        if (isFolder !== undefined && filePath !== undefined) {
            runInAction(async () => {
                await UserService.removeImage({ isFolder, filePath });
                this.resetData();
            });
        }
    }

    // updating the current session metadata
    updateCurrentMetaDataId(metaDataId) {
        this.metaDataId = metaDataId;
    }

    // * states for searching

    name = undefined;

    email = undefined;

    location = undefined;

    work_experience = undefined;

    education = undefined;

    searchResult = [];

    message = "";

    gender = "";

    age = undefined;

    isSearchModalOpen = true;

    isLoading = false;

    startTime = undefined;

    endTime = undefined;

    timeInterval = undefined;

    isSearched = false;

    toggleSearchModalOpen = () => {
        this.isSearchModalOpen = !this.isSearchModalOpen;
        this.message = "";
    };

    setName = (name) => {
        this.name = name;
    };

    setEmail = (email) => {
        this.email = email;
    };

    setLocation = (location) => {
        this.location = location;
    };

    setWork_Experience = (work_experience) => {
        this.work_experience = work_experience;
    };

    setEducation = (education) => {
        this.education = education;
    };

    setGender = (gender) => {
        this.gender = gender;
    };

    setAge = (age) => {
        this.age = age;
    };

    setSearchResult = (searchResult) => {
        this.searchResult = searchResult;
    };

    setMessage = (message) => {
        this.message = message;
    };

    setIsLoading = (loading) => {
        this.isLoading = loading;
    };

    setStartTime = () => {
        this.startTime = new Date();
    };

    setEndTime = () => {
        this.endTime = new Date();
    };

    setTimeInterval = () => {
        this.timeInterval = Math.abs((this.endTime - this.startTime) / 1000);
        //
    };

    setIsSearched = (value) => {
        this.isSearched = value;
    };

    search = async () => {
        this.setIsLoading(true);
        this.setStartTime();
        this.setIsSearched(true);
        const responce = await ManageDataService.search(
            this.name,
            this.email,
            this.location,
            this.work_experience,
            this.education,
            this.gender,
            this.age
        );
        //

        runInAction(() => {
            if (responce) {
                const { metaData, success } = responce;
                this.setEndTime();
                this.setTimeInterval();

                this.setIsLoading(false);

                if (success) {
                    this.setMessage(success);
                }

                if (metaData) {
                    // this.setSearchResult(metaData);
                    this.cvCandidateSearchedData = this.refactorMetadata(metaData);
                }
            }
        });
    };

    resetSearchState = () => {
        this.name = undefined;

        this.email = undefined;

        this.location = undefined;

        this.work_experience = undefined;

        this.education = undefined;

        this.gender = "";

        this.age = undefined;

        // this.searchResult = [];

        this.cvCandidateSearchedData = [];

        this.isSearchModalOpen = true;

        this.message = "";

        this.startTime = undefined;

        this.endTime = undefined;

        this.isSearched = false;
    };

    // audit screen states

    currentSelectedAuditUserType = "";

    currentSelectedAuditModuleName = "";

    // website logo main
    websiteMainLogo = "";

    // website logo main
    footerLogo = "";

    // website logo main
    faviconLogo = "";

    navbarLogo = "";

    // fetch metadata
    fetchMetaData = async (userType) => {
        let dataCount;
        // console.log(userType);

        // IF USERTYPE IS NOT SUPERADMIN THEN ONLY FETCH METADATA AND COUNT
        if (userType !== "superadmin") {
            dataCount = await UserService.getMetaDataCount(userType, this.currentUserId);
        }
        if (dataCount) dataCount = dataCount.count;
        if (!dataCount) dataCount = 0;

        console.log("dataCount", dataCount);

        const startCount = 0;

        // imp offset range is to data pull data in how much chunks
        const OFFSET_RANGE = 500;
        const limit = OFFSET_RANGE;

        let finalFetchedData;
        // eslint-disable-next-line prefer-const
        finalFetchedData = [];
        async function fetchDataInChunks(iterateCount: any, dataOffsetCount: any, dataLimit, that): Promise<any> {
            // let count = iterateCount;
            // let offset = dataOffsetCount;
            const count = iterateCount;
            const offset = dataOffsetCount;
            // console.log("count", count);

            if (!(count > 0) && userType !== "superadmin") {
                LogService.info("offset,limit ", { limit, offset });

                that.setIsLoading(true);
                const fetchedMetaData = await UserService.OutputViewerData(userType, that.currentUserId, dataOffsetCount, dataLimit);

                if (fetchedMetaData) {
                    that.setIsLoading(false);
                }
                // eslint-disable-next-line no-param-reassign
                finalFetchedData = [...finalFetchedData, ...fetchedMetaData.metaData];
                // updating main state metadata with merged fetched data
                runInAction(() => {
                    // eslint-disable-next-line no-param-reassign
                    that.cvMetaData = finalFetchedData;
                });

                // setTimeout(() => {
                //     // set recursive count states
                //     count -= OFFSET_RANGE;
                //     offset += OFFSET_RANGE;

                //     if (iterateCount === finalFetchedData.length) {
                //         count = 0;
                //     }
                //     fetchDataInChunks(count, offset, dataLimit, that);
                // }, 1000);
                return finalFetchedData;
            }

            return finalFetchedData;
        }

        await fetchDataInChunks(dataCount, startCount, limit, this);
    };

    // update cvMeta data  state
    cvMetaDataOperations = async (type, cvId, data?) => {
        //
        //
        //
        if (type === "update") {
            const updateCvMetaData: [] = this.cvMetaData.map((cvObj: any) => {
                // eslint-disable-next-line no-underscore-dangle
                if (cvObj._id === cvId) {
                    // eslint-disable-next-line no-param-reassign
                    cvObj = { ...cvObj, ...data };

                    return cvObj;
                }
                return cvObj;
            }) as [];
            this.cvMetaData = [...updateCvMetaData];
        }

        if (type === "delete") {
            const updateCvMetaData: [] = this.cvMetaData.filter((cvObj: any) => {
                // eslint-disable-next-line no-underscore-dangle
                return cvObj._id !== cvId;
            }) as [];
            this.cvMetaData = [...updateCvMetaData];
        }
    };

    // refactor data before fiding to the material table
    refactorMetadata = (metaData: any) => {
        // eslint-disable-next-line no-param-reassign
        metaData = metaData.filter((d: any) => d.status === "processed");

        // * refactoring metadata for material table
        const newprossedDataArray = metaData.map((metadata) => {
            const returnObject = {};
            const metadatakeys = Object.keys(metadata);
            // eslint-disable-next-line array-callback-return
            metadatakeys.map((metadatakey) => {
                if (metadatakey === "_id") {
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    returnObject[`metaDataId`] = metadata._id;
                }
                if (metadatakey === "processedDataId") {
                    const { processedDataId } = metadata;
                    const processedDataKeys = Object.keys(processedDataId);
                    // eslint-disable-next-line array-callback-return
                    processedDataKeys.map((processeddatakey) => {
                        if (processeddatakey === "processedData") {
                            const { processedData } = processedDataId;
                            // eslint-disable-next-line array-callback-return
                            processedData.map((processeddata) => {
                                returnObject[`${processeddata.label}`] = processeddata.data;
                            });
                        } else {
                            returnObject[`${processeddatakey}`] = processedDataId[`${processeddatakey}`];
                        }
                    });
                } else if (
                    metadatakey === "fileId" ||
                    metadatakey === "isAutomatic" ||
                    metadatakey === "isSemiAutomatic" ||
                    metadatakey === "createdAt" ||
                    metadatakey === "updatedAt" ||
                    metadatakey === "__v" ||
                    metadatakey === "isActive"
                ) {
                    // pass
                } else {
                    returnObject[`${metadatakey}`] = metadata[metadatakey];
                }
            });
            return returnObject;
        });
        return newprossedDataArray;
    };

    // fetch metadata
    // fetchProcessedData = async (userType) => {
    //     admin

    //     let dataCount = await UserService.getMetaDataCount(userType, this.currentUserId);
    //     dataCount = dataCount.count;
    //     const OFFSET_RANGE = 10;
    //     const LIMIT_RANGE = 10;

    //     const offsetCount = 0;
    //     const limit = LIMIT_RANGE;

    //     let finalFetchedData;
    //     finalFetchedData = [];
    //     async function fetchDataInChunks(iterateCount: any, dataOffsetCount: any, dataLimit, that): Promise<any> {
    //         let count = iterateCount;
    //         let offset = dataOffsetCount;

    //         if (count > 0) {
    //
    //             // fetch data in chunks
    //             const fetchedMetaData = await UserService.getMetaData_v2(userType, that.currentUserId, dataOffsetCount, dataLimit);
    //             const newprossedDataArray = that.refactorMetadata(fetchedMetaData.metaData);
    //             // eslint-disable-next-line no-param-reassign
    //             finalFetchedData = [...finalFetchedData, ...newprossedDataArray];
    //             // updating main state metadata with merged fetched data
    //             runInAction(() => {
    //                 // eslint-disable-next-line no-param-reassign
    //                 that.cvMetaData = finalFetchedData;
    //             });

    //             setTimeout(() => {
    //                 // set recursive count states
    //                 count -= OFFSET_RANGE;
    //                 offset += OFFSET_RANGE;

    //                 if (iterateCount === finalFetchedData.length) {
    //                     count = 0;
    //                 }
    //                 fetchDataInChunks(count, offset, dataLimit, that);
    //             }, 1000);
    //             return finalFetchedData;
    //         }

    //         return finalFetchedData;
    //     }

    //     await fetchDataInChunks(dataCount, offsetCount, limit, this);
    // };

    clearcvMetadata() {
        LogService.info("clearing cvmetadata");
        this.cvMetaData = [];
    }

    get candidatesData() {
        LogService.info("candidatesData: triggered");
        return this.cvMetaData?.length > 0 && this.refactorMetadata(this.cvMetaData);
    }

    // v2 methods

    setOutputViewPath(path) {
        this.outputViewPath = path;
    }

    public setBatchLogs(data) {
        this.batchLogs = data;
    }

    public updateBatchLogs(data) {
        const currentLogs = this.batchLogs;

        // check whether the duplicate entry
        if (currentLogs.length > 0) {
            const isDuplicated = currentLogs.filter((item) => item._id === data._id);

            if (isDuplicated.length === 0) {
                const updatedLogs = [data].concat(currentLogs);
                this.setBatchLogs(updatedLogs);
            }
        } else {
            const updatedLogs = [data].concat(currentLogs);
            this.setBatchLogs(updatedLogs);
        }
    }
}
