/** @format */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */

import { makeAutoObservable, toJS } from "mobx";
import socketIoClient from "socket.io-client";
import { NotificationManager } from "../../components/common/react-notifications";
import {
    BATCH_PROGRESS_COMPLETE_UPDATE,
    BATCH_PROGRESS_COUNT,
    BATCH_PROGRESS_COUNT_UPDATE,
    CONFIRM_FOOTER_TEXT,
    INVOICE_COORDINATES,
    ON_AUTO_SELECT_TEMPLATE,
    ON_BATCH_ERROR,
    ON_CREATE_TEMPLATE_IDENTIFIER,
    PROCESSED_DATA,
} from "../../constants";
import LogService from "../../services/LogService";
import UserService from "../../services/UserService";
import { socketBaseURL } from "../../utils/API";
import ProcessState from "./ProcessState";
import RefactoringState from "./RefactoringState";
import UserState from "./UserState";
import UIState from "./UIState";

export default class SocketState {
    socket: any;

    socketId: string;

    ProcessState: ProcessState;

    UserState: UserState;

    RefactoringState: RefactoringState;

    UIState: UIState;

    constructor(processState, refactoringState, userState, uiState) {
        // super();
        this.ProcessState = processState;
        this.RefactoringState = refactoringState;
        this.UserState = userState;
        this.UIState = uiState;

        makeAutoObservable(this);
    }

    socketInit() {
        const socket = socketIoClient(socketBaseURL);
        this.socket = socket;
        socket.on("connect", () => {
            this.socketId = socket.id;

            // creating user room
            // console.log(`userId -> ${JSON.stringify(this.UserState.userData.userId)}`);
            this.socket.emit("userRoom", { userId: this.UserState.userData.userId });

            this.onSocketListen();
        });
    }

    // classesList: Array<any> = [];

    // async loadClassesList() {
    //     const fetchClassesList = await UserService.fetchClassessList();
    //     const { classNames } = fetchClassesList.invoiceClassesList;
    //     this.classesList = classNames;
    // }

    // THESE IS REFACTORING FOR UI DISPLAY WHILE SENDING FOR DATA EXTRACTION SHOULD BE REVERTED
    // eslint-disable-next-line consistent-return
    // eslint-disable-next-line class-methods-use-this
    // async refactorCoordinates(coordinates: any): Promise<any> {
    //     const { classesList } = this;

    //     const deepCopiedYoloCoordinates = JSON.parse(JSON.stringify(coordinates));
    //     // console.log(deepCopiedYoloCoordinates);
    //     // FOR SINGLE PAGE ARRAY
    //     if (Array.isArray(deepCopiedYoloCoordinates)) {
    //         deepCopiedYoloCoordinates.map((yoloObj) => {
    //             const currentClassData = classesList.find((classObj) => classObj.className === yoloObj.comment);
    //             yoloObj.comment = currentClassData.displayName;
    //             yoloObj.className = currentClassData.className;
    //             // yoloObj.dataType = currentClassData.dataType;
    //             // yoloObj.labelType = currentClassData.labelType;
    //             return yoloObj;
    //         });
    //         return deepCopiedYoloCoordinates;
    //     }

    //     // ON MULTI PAGE OBJECT
    //     if (!Array.isArray(deepCopiedYoloCoordinates)) {
    //         Object.keys(deepCopiedYoloCoordinates).forEach((pageName) => {
    //             deepCopiedYoloCoordinates[pageName].map((yoloObj) => {
    //                 const currentClassData = classesList.find((classObj) => classObj.className === yoloObj.comment);
    //                 yoloObj.comment = currentClassData.displayName;
    //                 yoloObj.className = currentClassData.className;
    //                 // yoloObj.dataType = currentClassData.dataType;
    //                 // yoloObj.labelType = currentClassData.labelType;
    //                 return yoloObj;
    //             });
    //         });
    //         return deepCopiedYoloCoordinates;
    //     }
    //     return "";
    // }

    // // eslint-disable-next-line consistent-return
    // addYoloClassTypesInSinglePageInvoice(singlePageInvoiceClasses: any): any {
    //     const classNames = this.classesList;
    //     try {
    //         // eslint-disable-next-line array-callback-return
    //         singlePageInvoiceClasses.map((classObj) => {
    //             const comment = classObj["comment"];
    //             const classDataType = classNames.find((obj) => obj["className"] === comment);
    //             classObj["dataType"] = classDataType["dataType"];
    //             classObj["labelType"] = classDataType["labelType"];
    //         });
    //         return singlePageInvoiceClasses;
    //     } catch (error) {
    //         LogService.error(`ExtractProcessEventService --> addYoloClassTypesInSinglePageInvoice --> error: ${error.message}`);
    //     }
    // }

    // // eslint-disable-next-line consistent-return
    // addYoloClassTypesInMultiPageInvoice(multiPageInvoiceClasses: any): any {
    //     const classNames = this.classesList;
    //     try {
    //         // eslint-disable-next-line array-callback-return
    //         Object.keys(multiPageInvoiceClasses).map((pageName) => {
    //             if (Array.isArray(multiPageInvoiceClasses[pageName])) {
    //                 // eslint-disable-next-line array-callback-return
    //                 multiPageInvoiceClasses[pageName].map((classObj) => {
    //                     if (classObj["comment"]) {
    //                         const comment = classObj["comment"];
    //                         const classDataType = classNames.find((obj) => obj["className"] === comment);
    //                         classObj["dataType"] = classDataType["dataType"];
    //                         classObj["labelType"] = classDataType["labelType"];
    //                     }
    //                 });
    //             }
    //         });
    //         return multiPageInvoiceClasses;
    //     } catch (error) {
    //         LogService.error(`ExtractProcessEventService --> addYoloClassTypesInMultiPageInvoice --> error: ${error.message}`);
    //     }
    // }

    // // eslint-disable-next-line class-methods-use-this
    // refactorClassesForDataExtraction(annotateClasses: any[] | any): any {
    //     // changing classname with comment keyword
    //     //     for single page data is array
    //     if (Array.isArray(annotateClasses)) {
    //         let deepCopiedAnnotateClasses: any[] = JSON.parse(JSON.stringify(annotateClasses));
    //         deepCopiedAnnotateClasses = deepCopiedAnnotateClasses
    //             .map((obj) => {
    //                 const yoloObj = this.classesList.find((item) => item.displayName === obj.comment);
    //                 const { className } = yoloObj;
    //                 // if class name is present then swapt class name with comment
    //                 // with workflow like get footer coordinate or auto didnt recive class name so  dont do anything
    //                 if (className) {
    //                     obj.comment = className;
    //                 }
    //                 return obj;
    //             })
    //             .filter((obj) => obj.comment); // map and filtering on comment. if comment prsent then processed to save on db

    //         deepCopiedAnnotateClasses = this.addYoloClassTypesInSinglePageInvoice(deepCopiedAnnotateClasses);

    //         return deepCopiedAnnotateClasses;
    //     }

    //     // for multi page it is object
    //     if (Array.isArray(annotateClasses) === false) {
    //         const deepCopiedClassObject: any[] = JSON.parse(JSON.stringify(annotateClasses));
    //         let updatedAnnotateObject = {};
    //         // iterating over object keys
    //         Object.keys(deepCopiedClassObject).forEach((pageName) => {
    //             updatedAnnotateObject[pageName] = [];
    //             // changing comment with classname
    //             const pageAnnoatationsClasses: [] = deepCopiedClassObject[pageName]
    //                 .map((obj) => {
    //                     const yoloObj = this.classesList.find((item) => item.displayName === obj.comment);
    //                     const { className } = yoloObj;
    //                     if (className) {
    //                         obj.comment = className;
    //                     }
    //                     return obj;
    //                 })
    //                 .filter((obj) => obj.comment); // map and filtering on comment. if comment prsent then processed to save on db;

    //             // combining with new updated object
    //             updatedAnnotateObject[pageName] = [...pageAnnoatationsClasses];
    //         });

    //         updatedAnnotateObject = this.addYoloClassTypesInMultiPageInvoice(updatedAnnotateObject);
    //         return updatedAnnotateObject;
    //     }

    //     return [];
    // }

    onSocketListen() {
        // on extracted process data response
        this.socket.on(INVOICE_COORDINATES, async (response) => {
            // LogService.info("coordinate BEFORE REFACTOR response", response);
            response = await this.RefactoringState.refactorClassesForUI(response);
            LogService.info("coordinate AFTER REFACTOR response", response);
            if (response) {
                NotificationManager.success("got-coordinates");

                setTimeout(() => {
                    if (Array.isArray(response)) {
                        this.ProcessState.annotatePages[this.ProcessState.currentPageName] = [...response];
                    }

                    if (!Array.isArray(response)) {
                        this.ProcessState.annotatePages = { ...response };
                    }
                }, 300);
            } else {
                NotificationManager.error("unable-to-predict-coordinates");
            }

            // stop loader
            this.ProcessState.setLoadingState("isCoordinateProcessing", false);
        });

        // on invoice data responce from socket
        this.socket.on(PROCESSED_DATA, async (response) => {
            if (response) {
                NotificationManager.success("processed-data");
                LogService.info("Processed Data Response:", response);
                this.ProcessState.setCurrentProcesseLineItemsdData({ ...response });
            } else {
                NotificationManager.error("unable-extract-processed-data");
            }

            // stop loading
            this.ProcessState.setLoadingState("isExtracting", false);
        });

        // on invoice data responce from socket
        this.socket.on(ON_AUTO_SELECT_TEMPLATE, async (response) => {
            //
            LogService.info("Auto template detection response:", response);
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { current_template_id } = response;
            if (current_template_id !== "None") {
                NotificationManager.success("template-detected-successfully");
                this.ProcessState.setInvoiceOptions("templateId", current_template_id);
            } else {
                NotificationManager.error("unable-to-detect-template");
            }

            // stop loader
            this.ProcessState.setLoadingState("isDetectingTemplate", false);
        });

        // ON_CREATE_TEMPLATE_IDENTIFIER
        this.socket.on(ON_CREATE_TEMPLATE_IDENTIFIER, async (response) => {
            LogService.info("Create template Identifier Response:", response);
            // eslint-disable-next-line
            this.ProcessState.setCurrentTemplateData("templateIdentifier", response["identifier"]);
        });

        // export const CONFIRM_FOOTER_TEXT = 'CONFIRM_FOOTER_TEXT';
        this.socket.on(CONFIRM_FOOTER_TEXT, async (response: any) => {
            LogService.info("CONFIRM_FOOTER_TEXT", response);
            if (response) {
                this.ProcessState.setLoadingState("isExtractingFooter", false);
                NotificationManager.success("footer-text-extraction-success");
                // single page invoice coordinate with footer data
                if (Array.isArray(response.singlePageInvoiceClasses)) {
                    // console.log(response.singlePageInvoiceClasses);
                    this.ProcessState.setVariableTemplate("singlePageInvoiceClasses", response.singlePageInvoiceClasses);
                    this.ProcessState.setFlages("isConfirmedModelOpen", true);
                }
                // Multipage page invoice coordinate with footer data
                if (!Array.isArray(response.multiPageInvoiceClasses)) {
                    this.ProcessState.setVariableTemplate("multiPageInvoiceClasses", response.multiPageInvoiceClasses);
                    this.ProcessState.setFlages("isConfirmedModelOpen", true);
                }
            } else {
                NotificationManager.error("error-in-footer-text-extraction");
                this.ProcessState.setLoadingState("isExtractingFooter", false);
            }
        });

        this.socket.on(BATCH_PROGRESS_COUNT_UPDATE, async (response) => {
            if (response) {
                this.UIState.setBatchProgressCount(response);
                localStorage.setItem(BATCH_PROGRESS_COUNT, String(response));
            }
        });

        this.socket.on(BATCH_PROGRESS_COMPLETE_UPDATE, async (response) => {
            if (response) {
                this.UIState.setBatchProgressCount(0);
                this.UIState.updateBatchLogs(response);
                NotificationManager.success("Batch Process Completed");
                setTimeout(() => {
                    localStorage.removeItem(BATCH_PROGRESS_COUNT);
                }, 3000);
                LogService.info("batch-log-status", response);
            }
        });

        this.socket.on(ON_BATCH_ERROR, async (response) => {
            if (response) {
                NotificationManager.error(response);
                this.UIState.setBatchProgressCount(0);
                setTimeout(() => {
                    localStorage.removeItem(BATCH_PROGRESS_COUNT);
                }, 3000);
            }
        });
    }
}
