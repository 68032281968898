/** @format */

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/sort-comp */

import React from "react";
import PropTypes from "prop-types";
import NotificationManager from "./NotificationManager";
import Notifications from "./Notifications";

class NotificationContainer extends React.Component {
    constructor(props) {
        super(props);
        NotificationManager.addChangeListener(this.handleStoreChange);
    }

    static propTypes = {
        enterTimeout: PropTypes.number,
        leaveTimeout: PropTypes.number,
    };

    static defaultProps = {
        enterTimeout: 400,
        leaveTimeout: 400,
    };

    state = {
        notifications: [],
    };

    componentWillUnmount = () => {
        NotificationManager.removeChangeListener(this.handleStoreChange);
    };

    handleStoreChange = (notifications) => {
        this.setState({
            notifications,
        });
    };

    handleRequestHide = (notification) => {
        NotificationManager.remove(notification);
    };

    render() {
        const { notifications } = this.state;
        const { enterTimeout, leaveTimeout } = this.props;
        return (
            <Notifications
                enterTimeout={enterTimeout}
                leaveTimeout={leaveTimeout}
                notifications={notifications}
                onRequestHide={this.handleRequestHide}
            />
        );
    }
}

export default NotificationContainer;
