/** @format */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
// localStorage.setItem('user_name', 'Rohit'); //store a key/value
// var retrievedUsername = localStorage.getItem('user_name'); //retrieve the key

import { makeAutoObservable, runInAction, toJS, computed, autorun } from "mobx";
import { AUTOMATIC, EDIT_INVOICE, EDIT_TEMPLATE, FIXED, MANUAL, SEMI_AUTOMATIC, VARIABLE } from "../../constants";
import UserService from "../../services/UserService";
// import { baseURL, socketBaseURL } from "../../utils/API";
import { baseURL } from "../../utils/API";
import RefactoringState from "./RefactoringState";

// TODO :@mohit
// 1.unwanted use of   property remove it

export default class ProcessState {
    RefactoringState: RefactoringState;

    // these is going to deside where the current screen is processing, edit, createmplate, or delete
    currentAction: undefined;

    isUPloaded: false;

    drag: false;

    // current uploaded file data
    currentFileData = {
        urlFilePath: undefined,
        urlFolderPath: undefined,
        totalPages: undefined,
        fileType: undefined,
        filePath: undefined,
        folderPath: undefined,
        selectedPageNumber: 1,
    };

    // storing all annotation details
    annotatePages = {};

    // all current process required states
    currentInvoiceOptions = {
        processType: undefined,
        invoiceType: undefined,
        // templateType: undefined,
        templateId: undefined,
        // number of garbeg pages that should be removed
        pageCount: undefined,
        garbagePageCount: undefined,
    };

    // loading states
    loadingStates = {
        isUpload: false,
        isCoordinateProcessing: false,
        isSaving: false,
        isExtracting: false,
        isDetectingTemplate: false,
        isExtractingFooter: false,
        isGoogleUpload: false,
    };

    flags = {
        isUploaded: false,
        isConfirmedModelOpen: false,
        isFooterDataConfirmed: false,
    };

    currentProcessingState = {
        currentProcessedData: undefined,
        currentLineItem: undefined,
        currentMetaDataId: undefined,
        isSaved: false,
        columnsData: undefined,
    };

    currentTemplateData = {
        templateId: "",
        isActive: true,
        templateName: "",
        templateType: undefined,
        lastPageCount: "0",
        templateIdentifier: "",
    };

    messages = {
        templateDetectedMessage: undefined,
    };

    variableTempalteCoorinates = {
        singlePageInvoiceClasses: [],
        multiPageInvoiceClasses: {},
    };

    // ALL THESE THING ARE COMPUTED VALUE FOR TEMPLATE DATA
    get isSemiAutomatic() {
        return this.currentInvoiceOptions.processType === SEMI_AUTOMATIC;
    }

    get isAutomatic() {
        return this.currentInvoiceOptions.processType === AUTOMATIC;
    }

    get isManual() {
        return this.currentInvoiceOptions.processType === MANUAL;
    }

    get singlePageInvoiceClasses() {
        if (this.currentFileData.totalPages === 1) {
            if (this.currentTemplateData.templateType === FIXED) return this.annotatePages["page-1"];
            if (this.currentTemplateData.templateType === VARIABLE) {
                if (this.flags.isFooterDataConfirmed === false) return this.annotatePages["page-1"];
                if (this.flags.isFooterDataConfirmed === true) return this.variableTempalteCoorinates.singlePageInvoiceClasses;
            }
        }
        return [];
    }

    get multiPageInvoiceClasses() {
        if (this.currentFileData.totalPages > 1) {
            if (this.currentTemplateData.templateType === FIXED) return this.annotatePages;
            if (this.currentTemplateData.templateType === VARIABLE) {
                if (this.flags.isFooterDataConfirmed === false) return this.annotatePages;
                if (this.flags.isFooterDataConfirmed === true) return this.variableTempalteCoorinates.multiPageInvoiceClasses;
            }
        }
        return {};
    }

    get templateTotalPages() {
        return this.currentFileData.totalPages;
    }

    get templateFolderPath() {
        return this.currentFileData.folderPath;
    }

    get lastPageCount() {
        return this.currentInvoiceOptions.garbagePageCount;
    }

    get templateFilePath() {
        return this.currentFileData.filePath;
    }

    // TODO: NEED TO REFACTOR THESE FILE SO MUCH IS HERE
    //  FIXME: REFACTOR THESE FOUR COORDINATES LOGIC IS EXTACTLY CAN same BE COMBINED
    /**
     * sudo code: from conformFooterModal pass name of for getting header or footer
     *      here by using that parameter find the value of named passed
     *      get FooterText(name)
     *
     *      while set footer
     *      setFooter(name, e.target.value)
     */
    // ********** FOR COMFIRMATION OF FOOTER DATA ************//
    // FOOTER_TEXT
    get FOOTER_TEXT() {
        if (this.currentFileData.totalPages === 1) {
            const FOOTER_TEXT_OBJ = this.variableTempalteCoorinates.singlePageInvoiceClasses.find(
                (yoloObj) => Boolean(yoloObj.FOOTER_TEXT) === true
            );
            if (FOOTER_TEXT_OBJ) return FOOTER_TEXT_OBJ.FOOTER_TEXT;
            return "";
        }
        if (this.currentFileData.totalPages > 1) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            return this.variableTempalteCoorinates.multiPageInvoiceClasses["FOOTER_TEXT"];
        }
    }

    // TODO: @mohit
    // dont give function names like setFOOTER_TEXT
    // give proper camel case names
    setFOOTER_TEXT(value) {
        if (this.currentFileData.totalPages === 1) {
            // eslint-disable-next-line array-callback-return
            let index;
            this.variableTempalteCoorinates.singlePageInvoiceClasses.forEach((yoloObj, i) => {
                if (Object.keys(yoloObj)[0] === "FOOTER_TEXT") {
                    index = i;
                }
            });

            this.variableTempalteCoorinates.singlePageInvoiceClasses[index].FOOTER_TEXT = value;

            // return FOOTER_TEXT_1_OBJ.FOOTER_TEXT_1;
        }
        if (this.currentFileData.totalPages > 1) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            this.variableTempalteCoorinates.multiPageInvoiceClasses["FOOTER_TEXT"] = value;
        }
    }

    // FOOTER_TEXT_1
    get FOOTER_TEXT_1() {
        if (this.currentFileData.totalPages === 1) {
            const FOOTER_TEXT_1_OBJ = this.variableTempalteCoorinates.singlePageInvoiceClasses.find(
                (yoloObj) => Boolean(yoloObj.FOOTER_TEXT_1) === true
            );
            if (FOOTER_TEXT_1_OBJ) return FOOTER_TEXT_1_OBJ.FOOTER_TEXT_1;
            return "";
        }
        if (this.currentFileData.totalPages > 1) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            return this.variableTempalteCoorinates.multiPageInvoiceClasses["FOOTER_TEXT_1"];
        }
    }

    setFOOTER_TEXT_1(value) {
        if (this.currentFileData.totalPages === 1) {
            // eslint-disable-next-line array-callback-return
            let index;
            this.variableTempalteCoorinates.singlePageInvoiceClasses.forEach((yoloObj, i) => {
                if (Object.keys(yoloObj)[0] === "FOOTER_TEXT_1") {
                    index = i;
                }
            });

            this.variableTempalteCoorinates.singlePageInvoiceClasses[index].FOOTER_TEXT_1 = value;
        }
        if (this.currentFileData.totalPages > 1) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            this.variableTempalteCoorinates.multiPageInvoiceClasses["FOOTER_TEXT_1"] = value;
        }
    }

    // HEADER_TEXT_1
    get HEADER_TEXT_1() {
        if (this.currentFileData.totalPages === 1) {
            const HEADER_TEXT_1_OBJ = this.variableTempalteCoorinates.singlePageInvoiceClasses.find(
                (yoloObj) => Boolean(yoloObj.HEADER_TEXT_1) === true
            );
            if (HEADER_TEXT_1_OBJ) return HEADER_TEXT_1_OBJ.HEADER_TEXT_1;
            return "";
        }
        if (this.currentFileData.totalPages > 1) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            return this.variableTempalteCoorinates.multiPageInvoiceClasses["HEADER_TEXT_1"];
        }
    }

    setHEADER_TEXT_1(value) {
        if (this.currentFileData.totalPages === 1) {
            // eslint-disable-next-line array-callback-return
            let index;
            this.variableTempalteCoorinates.singlePageInvoiceClasses.forEach((yoloObj, i) => {
                if (Object.keys(yoloObj)[0] === "HEADER_TEXT_1") {
                    index = i;
                }
            });

            this.variableTempalteCoorinates.singlePageInvoiceClasses[index].HEADER_TEXT_1 = value;
        }
        if (this.currentFileData.totalPages > 1) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            this.variableTempalteCoorinates.multiPageInvoiceClasses["HEADER_TEXT_1"] = value;
        }
    }

    // HEADER_TEXT_2
    get HEADER_TEXT_2() {
        if (this.currentFileData.totalPages === 1) {
            const HEADER_TEXT_2_OBJ = this.variableTempalteCoorinates.singlePageInvoiceClasses.find(
                (yoloObj) => Boolean(yoloObj.HEADER_TEXT_2) === true
            );
            if (HEADER_TEXT_2_OBJ) return HEADER_TEXT_2_OBJ.HEADER_TEXT_2;
            return "";
        }
        if (this.currentFileData.totalPages > 1) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            return this.variableTempalteCoorinates.multiPageInvoiceClasses["HEADER_TEXT_2"];
        }
    }

    setHEADER_TEXT_2(value) {
        if (this.currentFileData.totalPages === 1) {
            // eslint-disable-next-line array-callback-return
            let index;
            // findindex header_text_2
            this.variableTempalteCoorinates.singlePageInvoiceClasses.forEach((yoloObj, i) => {
                if (Object.keys(yoloObj)[0] === "HEADER_TEXT_2") {
                    index = i;
                }
            });

            this.variableTempalteCoorinates.singlePageInvoiceClasses[index].HEADER_TEXT_2 = value;
        }
        if (this.currentFileData.totalPages > 1) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            this.variableTempalteCoorinates.multiPageInvoiceClasses["HEADER_TEXT_2"] = value;
        }
    }

    errors = {
        autoTemplateDetectionError: undefined,
        coOrdinatePredictionError: false,
        processedDataExtractionError: false,
        footerTextError: false,
    };

    // set current action
    setCurrentAction(action) {
        this.currentAction = action;
    }

    // used by sockerListner
    setCurrentProcesseLineItemsdData(processedDataState) {
        this.currentProcessingState.currentProcessedData = processedDataState;
        this.currentProcessingState.currentLineItem = processedDataState.items;

        const columnArr = [];
        if (processedDataState.items.length > 0) {
            const data = processedDataState.items[0];
            const keys = Object.keys(data);
            if (keys.length > 0) {
                keys.forEach((key) => {
                    const filter = this.RefactoringState.classesList.filter((x) => x.className === key);
                    if (filter[0]?.displayName !== undefined) {
                        const obj = { field: key, title: filter[0].displayName };
                        columnArr.push(obj);
                    }
                });
            }
            this.currentProcessingState.columnsData = columnArr;
            console.log(keys);
            console.log(`data shafnas -> ${JSON.stringify(data)}`);
        }
    }

    // used from ui to update state
    setCurrentProcessedData = (data) => {
        this.currentProcessingState.currentProcessedData = { ...this.currentProcessingState.currentProcessedData, ...data };
    };

    // used by react table to edit lines items
    updateMyData = (rowIndex, columnId, value) => {
        // eslint-disable-next-line array-callback-return
        this.currentProcessingState.currentLineItem = this.currentProcessingState.currentLineItem.map((row, index) => {
            if (index === rowIndex) {
                return {
                    ...this.currentProcessingState.currentLineItem[rowIndex],
                    [columnId]: value,
                };
            }
            return row;
        });
    };

    //
    setCurrentMetaDataId = (metaDataId) => {
        this.currentProcessingState.currentMetaDataId = metaDataId;
    };

    constructor(refactoringState) {
        this.RefactoringState = refactoringState;
        makeAutoObservable(this);
    }

    setAnnotatePageData(data) {
        // const currentPageName = `page-${this.currentFileData.selectedPageNumber}`;
        this.annotatePages[this.currentPageName] = data;
    }

    getCurrentPageAnnotateData() {
        // return this.annotatePages[this.currentPageName];
        if (this.annotatePages[this.currentPageName]) {
            return this.annotatePages[this.currentPageName];
        }
        return [];
    }

    get currentPageName() {
        return `page-${this.currentFileData.selectedPageNumber}`;
    }

    public setCurrentInvoiceData(data) {
        const { isPdf, fileData } = data.uploadResponse;

        // IF FILE IS  A PDF AND SINGLE PAGE
        if (fileData.pages === 1) {
            this.currentFileData.urlFilePath = `${baseURL}/${fileData.filePath}`;
            this.currentFileData.totalPages = +fileData.pages;
            this.currentFileData.fileType = isPdf ? "pdf" : "image";
            this.currentFileData.filePath = fileData.filePath;
            return "";
        }
        // IF FILE IS  A PDF AND MULTI PAGE
        if (fileData.pages > 1) {
            this.currentFileData.urlFilePath = `${baseURL}/${fileData.folderPath}/page-1.png`;
            this.currentFileData.totalPages = +fileData.pages;
            this.currentFileData.fileType = "pdf";
            this.currentFileData.urlFolderPath = `${baseURL}/${fileData.folderPath}`;
            this.currentFileData.folderPath = `${fileData.folderPath}`;

            return "";
        }

        return "";
    }

    // change the current page view
    public setCurrentPage(pageNumber) {
        this.currentFileData.selectedPageNumber = pageNumber;
        this.currentFileData.urlFilePath = `${this.currentFileData.urlFolderPath}/page-${pageNumber}.png`;
    }

    public refreshMarker() {
        const imgPath = this.currentFileData.urlFilePath;
        this.currentFileData.urlFilePath = "";

        setTimeout(() => {
            runInAction(() => {
                this.currentFileData.urlFilePath = imgPath;
            });
        }, 100);
    }

    // SETTING LOADDING STATES
    public setLoadingState(optionType, value) {
        this.loadingStates[optionType] = value;
    }

    // SETTING STATE
    public setInvoiceOptions(optionType, value) {
        this.currentInvoiceOptions[optionType] = value;
    }

    public setCurrentProcessingState(optionType, value) {
        // console.log(optionType, value);
        this.currentProcessingState[optionType] = value;
    }

    public setErrors(optionType, value) {
        // console.log(optionType, value);
        this.errors[optionType] = value;
    }

    public setMessages(optionType, value) {
        this.messages[optionType] = value;
    }

    // setting name and
    public setCurrentTemplateData(optionType, value) {
        // console.log(optionType, value);
        this.currentTemplateData[optionType] = value;
        // console.log(this.currentTemplateData.templateIdentifier);
    }

    public setFlages(flageName, value) {
        this.flags[flageName] = value;
        // console.log(this.flags);
    }

    public setVariableTemplate(option, value) {
        this.variableTempalteCoorinates[option] = value;
    }

    // CLEARING STATES
    clearCurrentFileState() {
        this.currentFileData.filePath = undefined;
        this.currentFileData.fileType = undefined;
        this.currentFileData.folderPath = undefined;
        this.currentFileData.selectedPageNumber = 1;
        this.currentFileData.totalPages = undefined;
        this.currentFileData.urlFilePath = undefined;
        this.currentFileData.urlFolderPath = undefined;
    }

    clearAnnotatePages() {
        this.annotatePages = {};
    }

    clearCurrentInvoiceOptions() {
        this.currentInvoiceOptions.processType = undefined;
        this.currentInvoiceOptions.invoiceType = undefined;
        this.currentInvoiceOptions.templateId = undefined;
        this.currentInvoiceOptions.pageCount = undefined;
    }

    clearLoadingState() {
        this.loadingStates.isUpload = false;
        this.loadingStates.isCoordinateProcessing = false;
        this.loadingStates.isSaving = false;
        this.loadingStates.isExtracting = false;
        this.loadingStates.isDetectingTemplate = false;
    }

    clearCurrentProcessingState() {
        this.currentProcessingState.currentProcessedData = undefined;
        this.currentProcessingState.currentLineItem = undefined;
        this.currentProcessingState.currentMetaDataId = undefined;
        if (this.currentAction !== EDIT_INVOICE) this.currentProcessingState.isSaved = false;
    }

    clearVariableTemplateData() {
        this.variableTempalteCoorinates.multiPageInvoiceClasses = {};
        this.variableTempalteCoorinates.singlePageInvoiceClasses = [];
    }

    clearFlags() {
        this.flags.isUploaded = false;
        this.flags.isConfirmedModelOpen = false;
        this.flags.isFooterDataConfirmed = false;
    }

    clearErrors() {
        this.errors.autoTemplateDetectionError = undefined;
    }

    clearTemplateState() {
        this.currentTemplateData.isActive = false;
        this.currentTemplateData.lastPageCount = "0";
        this.currentTemplateData.templateIdentifier = "";
        this.currentTemplateData.templateName = "";
        this.currentTemplateData.templateType = "";
        this.currentTemplateData.templateId = "";
    }

    // these function would remove file from server if the isSavedSate is false
    // eslint-disable-next-line class-methods-use-this
    clearImagesFile() {
        const { totalPages, filePath, folderPath } = this.currentFileData;
        const reqData = {
            isFolder: totalPages !== 1,
            removePath: totalPages === 1 ? filePath : folderPath,
        };

        UserService.deleteFileAndImage(reqData);
    }

    clearMessageState() {
        this.messages.templateDetectedMessage = undefined;
    }

    clearAllProcessingState() {
        // send these request only when data is not saved
        if (!this.currentProcessingState.isSaved) this.clearImagesFile(); //!  always keep it at top
        this.clearCurrentFileState();
        this.clearAnnotatePages();
        this.clearMessageState();
        this.clearCurrentInvoiceOptions();
        this.clearLoadingState();
        this.clearCurrentProcessingState();
        this.clearErrors();
        this.clearFlags();
        this.clearTemplateState();
        this.clearVariableTemplateData();
    }

    setDrag(value) {
        this.drag = value;
    }

    // FETCHING INVOICE DETAIL used on edit invoie route
    async getInvoiceDetails(metaDataId: string) {
        const { errorMessage, invoiceData } = await UserService.InvoiceDetails(metaDataId);
        if (!errorMessage) {
            // setting tabledata and general info
            this.setCurrentProcesseLineItemsdData(invoiceData.processedDataId.processedData);
            this.setCurrentProcessingState("isSaved", true);
            this.setCurrentProcessingState("currentMetaDataId", invoiceData._id);
            this.setInvoiceOptions("processType", invoiceData.processType);
            this.setInvoiceOptions("invoiceType", invoiceData.invoiceType);
            this.annotatePages = invoiceData.annotatePages;

            const documentData = {
                uploadResponse: {
                    isPdf: invoiceData.invoicePageCount !== 1,
                    fileData: {
                        pages: invoiceData.invoicePageCount,
                        filePath: invoiceData.invoicePageCount === 1 ? invoiceData.docPath : undefined,
                        folderPath: invoiceData.invoicePageCount > 1 ? invoiceData.docPath : undefined,
                    },
                },
            };

            this.setCurrentInvoiceData(documentData);
        }
    }

    /**
     * HOW TEMPLATE CRATION WORKS
     *  we can devide logic in steps
     *  step1: upload po
     *  step2: marking
     *         1. fixed then save directly do nothing
     *         2. varialbe then comfirm dynamic hearder identifier
     */

    // eslint-disable-next-line class-methods-use-this
    async getTemplateDetials(tempalteId: string) {
        const { errorMessage, tempalteDetails } = await UserService.getTemplateDetials(tempalteId);

        // console.log("Template Details", tempalteDetails);

        const ls = ["FOOTER_TEXT", "FOOTER_TEXT_1", "HEADER_TEXT_1", "HEADER_TEXT_2", "LAST_PAGE_NAME"];

        const {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _id,
            isAutomatic,
            isBatchManual,
            isSemiAutomatic,
            templateType,
            totalPages,
            imagePath,
            folderPath,
            isActive,
            lastPageCount,
            multiPageInvoiceClasses,
            singlePageInvoiceClasses,
            templateIdentifier,
            templateName,
        } = tempalteDetails;

        // step1 --> like uploading
        const documentData = {
            uploadResponse: {
                isPdf: totalPages !== 1,
                fileData: {
                    pages: totalPages,
                    filePath: totalPages === 1 ? imagePath : undefined,
                    folderPath: totalPages > 1 ? folderPath : undefined,
                },
            },
        };
        this.setCurrentInvoiceData(documentData);

        // step2: configuring template
        let processType;
        if (isAutomatic) processType = AUTOMATIC;
        if (isSemiAutomatic) processType = SEMI_AUTOMATIC;
        if (isBatchManual) processType = MANUAL;
        this.setFlages("isUploaded", true);
        this.setCurrentProcessingState("isSaved", true);
        this.setInvoiceOptions("processType", processType);
        this.setCurrentTemplateData("templateId", _id);
        this.setCurrentTemplateData("templateType", templateType);
        this.setCurrentTemplateData("isActive", isActive);
        this.setCurrentTemplateData("templateName", templateName);
        this.setCurrentTemplateData("templateType", templateType);
        this.setCurrentTemplateData("lastPageCount", lastPageCount);
        this.setCurrentTemplateData("templateIdentifier", templateIdentifier);

        /**
         * Sudo code:
         *  here we are having two states
         *  1. for co-ordiante marker
         *  2. other for variable invoice template marked data
         *
         *  only differnce between two state is that variable tempalte state identifiers for header footer
         */

        if (totalPages === 1) {
            // setting variabe state
            this.setVariableTemplate("singlePageInvoiceClasses", singlePageInvoiceClasses);

            // console.log("singlePageInvoiceClasses", singlePageInvoiceClasses);

            const singlePageInvoiceClassesWithoutIdentifier = singlePageInvoiceClasses.filter(
                (items) => !ls.includes(Object.keys(items)[0])
            );
            const singlePageClassesForUI = await this.RefactoringState.refactorClassesForUI(singlePageInvoiceClassesWithoutIdentifier);

            // setting state for showing user
            setTimeout(() => {
                this.annotatePages[this.currentPageName] = [...singlePageClassesForUI];
            }, 300);
        }
        if (totalPages > 1) {
            // setting variabe state
            const multiPageInvoiceClassesWithOutIdentifier = {};
            Object.keys(multiPageInvoiceClasses).forEach((key) => {
                if (!ls.includes(key)) {
                    multiPageInvoiceClassesWithOutIdentifier[key] = multiPageInvoiceClasses[key];
                }
            });

            const multiPageClassesForUI = await this.RefactoringState.refactorClassesForUI(multiPageInvoiceClassesWithOutIdentifier);

            // setting state for showing user
            setTimeout(() => {
                this.annotatePages = { ...multiPageClassesForUI };
            }, 300);
        }
    }
}
