/** @format */

// import { makeAutoObservable, autorun } from "mobx";
import DemoState from "./DemoState";
import UIState from "./UIState";
import ProcessState from "./ProcessState";
import UserState from "./UserState";
import SocketState from "./SocketState";
import RefactoringState from "./RefactoringState";
// import UIState from "./UIState";
// import UserState from "./UserState";

// ADD ENTRY OF YOUR STATE HERE
class RootState {
    DemoState: DemoState;

    UserState: UserState;

    UIState: UIState;

    ProcessState: ProcessState;

    SocketState: SocketState;

    RefactoringState: RefactoringState;

    constructor() {
        // makeAutoObservable(this);
        // this.UIState = new UIState();
        this.DemoState = new DemoState();

        this.UserState = new UserState();
        this.UIState = new UIState();
        this.RefactoringState = new RefactoringState();
        this.ProcessState = new ProcessState(this.RefactoringState);
        this.SocketState = new SocketState(this.ProcessState, this.RefactoringState, this.UserState, this.UIState);
    }
}

export default RootState;
